<template>
  <div id="depoimentos">
    <custom-lista
      v-if="!carregando"
      class="depoimento-lista"
      :titulo="titulo"
      :itens="itensRef"
      width="300"
      height="auto"
    />
  </div>
</template>

<script setup>
const { t, locale } = useI18n();
const { data: depoimentos } = await useFetch("/api/site/depoimentos", {
  headers: { "Content-Language": locale },
});
const carregando = ref(true);
const titulo = t("Depoimentos dos nossos clientes");
let itensRef = ref([]);

onMounted(() => {
  Object.values(depoimentos.value).forEach((item) => {
    itensRef.value.push({
      texto: item.depoimento,
      subtitulo: item.nome,
    });
  });
  carregando.value = false;
});
</script>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Depoimentos",
};
</script>
