<template>
  <div id="franquia" class="franquia">
    <div class="container-fluid">
      <div class="row centralizar">
        <div class="col-md-6 mb-5">
          <h2>{{ $t("Seja um franqueado") }}</h2>

          <p v-if="locale == 'pt'" class="my-5">
            Por que escolher a Sorrifácil? Porque somos diferentes. Vendemos
            muito mais que um negócio, vendemos um própósito de vida.
          </p>

          <p v-if="locale == 'es'" class="my-5">
            ¿Por qué elegir Sorrifácil? Porque somos diferentes. vendemos mucho
            más que un negocio, vendemos un propósito de vida.
          </p>

          <NuxtLink
            class="botao botao-branco"
            :to="localePath('franquia', locale)"
          >
            {{ $t("Saiba mais") }}
          </NuxtLink>
        </div>
        <div class="col-md-6 video-container">
          <iframe
            :title="tituloVideo"
            class="video"
            src="https://www.youtube.com/embed/sQKp8VfJNAs"
            frameborder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope;"
            allowfullscreen
          ></iframe>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Franquia",
};
</script>

<script setup>
const { t, locale } = useI18n();
const localePath = useLocalePath();
const tituloVideo = t("Seja um franqueado");
</script>
